
              @import "@/assets/css/variables.scss";
            












































































































































































































































































































































































































































































.piece_list {
  // width: 100%;
  margin-top: 16px;
  .piece_item {
    width: 100%;
    padding: 24px;
    background-color: $white;
    border-radius: 8px;
    margin-bottom: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    .item_top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: $text-secondary;
      font-size: 14px;
      img {
        width: 24px;
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }
    }
    .item_bottom {
      color: $text-primary;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      .bottom_currency {
        @include rtl-sass-prop(padding-right, padding-left, 5px);
      }
    }

    &:hover {
      box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.1);
    }
  }
  .active {
    border: 1px solid $primary;
  }
}
.table_panel {
  transition: all transform 2s;
}
.table_search {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .el-form {
    width: 100%;
    display: flex;
    align-items: center;
    .el-form-item {
      width: 240px;
      margin-bottom: 16px;
      @include rtl-sass-prop(margin-right, margin-left, 16px);
    }
  }
  .el-button {
    height: 32px;
    margin-bottom: 16px;
  }
  @include screen-mobile {
    flex-direction: column;
    .el-form {
      flex-direction: column;
      .el-form-item {
        width: 100%;
        @include rtl-sass-prop(margin-right, margin-left, 0px);
      }
    }
    .el-button {
      width: 100%;
    }
  }
}
